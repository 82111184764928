import Hammer from "hammerjs"

document.addEventListener("readystatechange", ev => {
    if (document.readyState === "interactive") {
        init()
    }
})

let switchTime = 5000
let globalIdx = 0
let imgBack = null
let imgTrans = null
let btnsContainer = null
let btns = []
let imagesSrc = []

function init() {

    imgBack = document.getElementById("hero-img-back")
    imgTrans = document.getElementById("hero-img-trans")
    btnsContainer = document.getElementById("hero-btns")

    let imgsJs = document.getElementsByClassName("hero-img-js")

    for (let i = 0; i < imgsJs.length; i++) {

        let btn = document.createElement("div")
        if (i === 0) {
            btn.classList.add("active")
        }
        btnsContainer.appendChild(btn)
        btn.onclick = () => {
            let ii = i
            clearTimeout(intervalRes)
            switchImg(ii)
        }
        btns.push(btn)

        imagesSrc.push(imgsJs[i].src)
    }

    let hammer = new Hammer(document.getElementById("hero-container"))
    hammer.on("pan", (ev) => {
        if (ev.isFinal) {
            clearTimeout(intervalRes)

            if (ev.deltaX < 0) {
                switchImg((globalIdx + 1) % imagesSrc.length)
            } else {
                if (globalIdx > 0) {
                    switchImg(globalIdx - 1)
                } else {
                    switchImg(imagesSrc.length - 1)
                }
            }
        }
    })
    autoTransition(imagesSrc[1])
}


let intervalRes
function autoTransition() {
    intervalRes = setTimeout(() => {
        let currentSrc = imgBack.src
        let currentIdx = imagesSrc.findIndex((elem, idx) => { return elem === currentSrc })
        let newIdx = 0
        if (currentIdx < imagesSrc.length - 1) {
            newIdx = currentIdx + 1
        }

        switchImg(newIdx)
        autoTransition()

    }, switchTime)
}


let chromeInterval = null
function switchImg(idx) {
    globalIdx = idx
    let imgSrc = imagesSrc[idx]

    //set trans src to new
    imgTrans.src = imgSrc

    //set btns classes
    for (let btn of btns) {
        btn.classList.remove("active")
    }
    btns[idx].classList.add("active")

    //set trans class to opaque, chrome workaroud
    
    if("ontransitionend" in imgTrans){
        imgTrans.ontransitionend = () => {
            //set bg src to new
            imgBack.src = imgSrc
            imgTrans.classList.remove("imgopaque")
        }
    }else{
        clearInterval(chromeInterval)
        chromeInterval = setInterval(() => {
            //set bg src to new
            imgBack.src = imgSrc
            imgTrans.classList.remove("imgopaque")
        }, 1000)
    }
    imgTrans.classList.add("imgopaque")
}
